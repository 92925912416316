import React from 'react'
import { Form } from 'antd'
import Wrapper from '../components/wrapper'
import SignUpForm from '../components/pageComponents/signupForm'

export default function SignUpPage({ location }) {
  const [form] = Form.useForm()

  return (
    <Wrapper location={location} title="SignUp | MedReps.com">
      <div className="mx-auto max-w-[1024px] mt-[100px] shadow-xl">
        <SignUpForm form={form} />
      </div>
    </Wrapper>
  )
}
